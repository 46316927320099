import navigationHistory from "./navigation-history";
import { isUserLoggedIn } from "../helpers/user-helper";
import { routesArray, oldWebsiteRoutes } from "../routes/index";
import { routes } from "../routes/index";
import { matchPath } from "react-router-dom";
import { lateExecute } from "../helpers/ui-helper";
import Tracking from "../tracking/index";
// import Pendo from "../tracking/pendo";

class NavigationMiddleware {
  apply = () => {
    navigationHistory.listen((location, action) =>
      this.handleNavigation(location, action)
    );
  };

  handleNavigation = (location) => {
    const route = routesArray.find((r) => matchPath(location.pathname, r));

    if (route) {
      if (this.#hasToRedirectToLogin(route)) {
        this.#redirectToLogin(location.pathname);
      }
    } else {
      const isOldRoute = oldWebsiteRoutes.find((r) =>
        matchPath(location.pathname, r)
      );
      if (isOldRoute) {
        routes.root.navigate();
      } else {
        this.#redirectToNotFound(location.pathname);
      }
    }

    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    // Pendo.setData();
    Tracking.pageViewEvent();
  };

  #hasToRedirectToLogin = (route) =>
    route.authRequired === true && !isUserLoggedIn();

  #redirectToLogin = (returnUrl) => {
    lateExecute(() => {
      if (window.location.pathname !== routes.login.path) {
        routes.login.navigate(returnUrl);
      }
    });
  };

  #redirectToNotFound = (requestedUrl) =>
    lateExecute(() => {
      if (window.location.pathname !== routes.login.path) {
        routes.notFound.navigate(requestedUrl);
      }
    });
}

export default new NavigationMiddleware();
