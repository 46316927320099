import React, { Component, Fragment } from "react";
import { getFormattedDate } from "../../../../../shared/helpers/date-helper";
import {
  isEmpty,
  stringToFloat,
} from "../../../../../shared/helpers/ui-helper";
import { convertTeeSetSideToRatingType } from "../../../../../shared/helpers/model-helper";
import { getParDisplay } from "../../../helpers/round-setup-helper";
import EstimatedHandicapModal from "../Modals/EstimatedHandicapModal";
import { store } from "../../../../../shared/store";
import {
  getDifferential,
  getDifferentialButton,
  getDifferentialInfoMessage,
} from "../../../../../shared/helpers/scores-helper";
import YesNoModal from "../../../../../shared/components/modals/YesNoModal";
import ShareRoundSummary from "../../../../golfer/components/Scores/ShareRoundSummary";
import { routes } from "../../../../../shared/routes";
import { getScoreStatsType } from "../../../../../shared/helpers/stats-helper";
import ChartType from "../../../../../shared/variables/score-chart-type";
import DeleteRoundConfirmationModal from "../../../../golfer/components/Modals/DeleteRoundConfirmationModal";

class Stats extends Component {
  constructor(props) {
    super(props);

    const trialGolfer =
      store.getState().profileReducer.golferProfileFetchReducer.trialGolfer;
    const handicapIndex =
      store.getState().profileReducer.golferProfileFetchReducer.golferProfile
        ?.handicap_index;
    const useScaling =
      store.getState().profileReducer.golferProfileFetchReducer.golferProfile
        ?.use_scaling;

    this.state = {
      estimatedHandicapDisplay: false,
      trialGolfer: trialGolfer,
      showDifferentialModal: false,
      hasHandicapIndex: handicapIndex != "NH" && handicapIndex != "WD",
      useScaling: useScaling,
      deleteRoundConfirmationModalOpen: false,
    };
  }

  noTeeNameValue = "N/A";

  closeDifferentialModal = () =>
    this.setState({ showDifferentialModal: false });

  showDifferentialModal = () => this.setState({ showDifferentialModal: true });

  showScoreCardButton = () => {
    const { score } = this.props;
    const shouldRenderButton = window.location.pathname.includes("stats");
    const statsType = getScoreStatsType(score.statistics);
    return (
      shouldRenderButton &&
      (statsType === ChartType.ADVANCED_STATS || statsType === ChartType.STATS)
    );
  };

  renderViewScoreCard = () => {
    const { score } = this.props;
    return (
      <div className="columns">
        <div className="row score-card__row">
          <div className="col auto h-centered">
            <button
              className="btn outline blue score-card__button"
              onClick={() => routes.statsCharts.navigate(score, true)}
            >
              View Scorecard
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderDeleteRoundButton = () => {
    const { score } = this.props;
    const canDeleteRound = score?.can_be_deleted;
    const postingAction = location.pathname.includes("post-score");
    let data = null;

    if (!this.props.hideDeleteButton && (canDeleteRound || postingAction))
      data = (
        <div className="row">
          <div
            className="col h-centered"
            style={{ textAlign: "center", marginTop: "16px" }}
          >
            <p>
              Made a scoring mistake?
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <button
                onClick={() =>
                  this.setState({
                    deleteRoundConfirmationModalOpen: true,
                  })
                }
                className="btn lnk blue no-pad delete-round-button"
              >
                &nbsp;Delete round
              </button>
            </p>
          </div>
        </div>
      );
    return data;
  };

  deleteRoundConfirmationModalClose = () => {
    this.setState({ deleteRoundConfirmationModalOpen: false });
  };

  render() {
    const { score } = this.props;
    if (score === null) {
      return null;
    }
    const displayEstimatedHandicap =
      ((this.state.useScaling && this.state.hasHandicapIndex) ||
        !this.state.useScaling) &&
      score.estimated_handicap_display;

    return (
      <div className="box-body your-trend">
        <div className="your-trend__container">
          <div className="your-trend__col">
            <label className="regular black centered large">Score</label>
            <strong className="your-trend__score">
              {score.adjusted_gross_score}
            </strong>
          </div>
          {!this.state.trialGolfer && (
            <div className="your-trend__col">
              <label className="regular black centered large">
                Differential
              </label>
              <strong className="your-trend__score">
                <span className="your-trend__differential">
                  {getDifferential(score, this.state.useScaling)}
                  {getDifferentialButton(score, this.showDifferentialModal)}
                </span>
              </strong>
            </div>
          )}
          <div className="your-trend__col">
            <div className="your-trend__extra">
              {this.renderFacilityName(score)}
              {this.renderCourseName(score)}
            </div>
          </div>
          <div className="your-trend__col">
            <div className="your-trend__extra">
              {`${this.renderTeeName(score)} ${
                this.state.trialGolfer
                  ? ""
                  : `${stringToFloat(score.course_rating, 1)}/${
                      score.slope_rating
                    }${this.getPar()}`
              }`}
              <br />
              {getFormattedDate(score.played_at)}
            </div>
          </div>
        </div>
        {displayEstimatedHandicap && !this.state.trialGolfer && (
          <div className="your-trend__title">
            Your pending Handicap Index
            <button
              onClick={() => this.setState({ estimatedHandicapDisplay: true })}
              className="info-button"
              aria-label="Open info dialog"
            >
              !
            </button>{" "}
            is {score.estimated_handicap_display}
            {this.state.hasHandicapIndex && score.number_of_holes == 9 && (
              <div className="info-message">
                Beginning in January 2024, 9-hole scores receive an 18-hole
                Score Differential based on an expected Score Differential for
                the holes not played. This score will be used for your Handicap
                Index. To learn more about this WHS change,{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://www.usga.org/content/usga/home-page/handicapping/world-handicap-system/2024-revision/" +
                    "2024-treatment-of-9-hole-scores-FAQ.html?utm_source=ghin&utm_medium=website&utm_campaign=whsfaq"
                  }
                >
                  click here
                </a>
                .
              </div>
            )}
          </div>
        )}
        <div>
          <ShareRoundSummary score={this.props.score} />
          {this.showScoreCardButton() && this.renderViewScoreCard()}
          {this.renderDeleteRoundButton()}
        </div>
        <EstimatedHandicapModal
          isOpen={this.state.estimatedHandicapDisplay}
          onClose={() => this.setState({ estimatedHandicapDisplay: false })}
        />
        <YesNoModal
          isOpen={this.state.showDifferentialModal}
          onAbort={this.closeDifferentialModal}
          onConfirm={this.closeDifferentialModal}
          className="modal-dialog"
          appElement={document.getElementById("root")}
          isNoVisible={false}
          yesTitle="Ok"
        >
          {getDifferentialInfoMessage()}
        </YesNoModal>
        <DeleteRoundConfirmationModal
          score={this.props.score}
          isHbhScoreWithAdvStats={this.props.isAdvStatsOn}
          isTrialGolfer={this.state.trialGolfer}
          isOpen={this.state.deleteRoundConfirmationModalOpen}
          onClose={this.deleteRoundConfirmationModalClose}
        />
      </div>
    );
  }

  renderFacilityName = (score) => {
    return !isEmpty(score.facility_name) &&
      score.facility_name !== score.course_name ? (
      <Fragment>
        {score.facility_name}
        <br />
      </Fragment>
    ) : null;
  };

  renderCourseName = (score) => score.course_name.replace("-", "\n");

  renderTeeName = (score) =>
    score.tee_name === this.noTeeNameValue ? "" : score.tee_name;

  getPar = () => {
    const { courseDetails, score } = this.props;

    if (!courseDetails || !courseDetails.TeeSets) return "";

    const teeSet = courseDetails.TeeSets.find(
      (x) => x.TeeSetRatingId.toString() === score.tee_set_id
    );

    return getParDisplay(
      teeSet,
      convertTeeSetSideToRatingType(score.tee_set_side),
      "/"
    );
  };
}

Stats.defaultProps = {
  courseDetails: {},
};

export default Stats;
