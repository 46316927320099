import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import ContentListHeader from "./ContentListHeader";
import ContentListItem from "./ContentListItem";
import NoResultsFound from "../../../../shared/components/notices/NoResultsFound";
import PccInfoModal from "../../../../shared/components/modals/PccInfoModal";
import ScoreLegend from "../../../../shared/components/legends/ScoreLegend";
import ContentListSubHeader from "./ContentListSubHeader";
import ScoreType from "../../../../shared/variables/stats-score-type";
import { getAdditionalData } from "../../../../shared/helpers/scores-helper";
import { updateStatsScores } from "../../actions";
import {
  deleteRoundStats,
  postEditRoundStatsReset,
} from "../../../post-score/actions";
import ConfirmDeleteStatsModal from "../shared/ConfirmDeleteStatsModal";

class ContentList extends Component {
  state = {
    showPccInfoModal: false,
    showDeleteRoundStatsModal: false,
    scoreForDelete: undefined,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { editedScore } = nextProps;
    if (editedScore && editedScore !== this.props.editedScore) {
      this.props.updateStatsScores(editedScore);
      this.props.postEditRoundStatsReset();
    }
  }

  getDisplayScores = () => {
    const { selectedClub } = this.props;

    const { scores } = this.props;

    let filteredScores = scores;
    if (selectedClub) {
      const filter = selectedClub.split("_");
      const filterClubAndTee = selectedClub.split("-");
      if (filterClubAndTee[1] && filterClubAndTee[1] !== "null") {
        filteredScores = scores.filter(
          (s) =>
            s.course_id === filterClubAndTee[0] &&
            s.tee_set_id === filterClubAndTee[1]
        );
      } else if (filter[1] && filter[1] !== "null") {
        filteredScores = scores.filter(
          (s) => s.course_name === filter[0] && s.tee_name === filter[1]
        );
      } else {
        filteredScores = scores.filter((s) => s.course_name === filter[0]);
      }
    }
    return filteredScores;
  };

  onClosePccInfoModal = () => this.setState({ showPccInfoModal: false });

  onShowPccInfoModal = () => this.setState({ showPccInfoModal: true });

  onDeleteRoundStatsConfirm = () => {
    const { scoreForDelete } = this.state;
    this.props.deleteRoundStats(scoreForDelete);
    this.setState({
      showDeleteRoundStatsModal: false,
      scoreForDelete: undefined,
    });
  };

  onDeleteRoundStats = (scoreForDelete) => {
    this.setState({ showDeleteRoundStatsModal: true, scoreForDelete });
  };

  markScoresAsDeleted = (scores, deleted) => {
    return scores.map((score) => {
      return { ...score, deleted: deleted };
    });
  };

  sortScores = (scores) => {
    return scores.sort((a, b) => {
      if (b.played_at !== a.played_at)
        return new Date(b.played_at) - new Date(a.played_at);
      else return new Date(b.posted_at) - new Date(a.posted_at);
    });
  };

  renderContent(scores, nineHole = false) {
    const {
      filter,
      onEditRoundStats,
      onViewRoundStats,
      golferActive,
      useScaling,
      hasHandicapIndex,
      trialGolfer,
    } = this.props;
    const style = nineHole ? { fontStyle: "italic" } : {};
    return scores.map((score) => (
      <ContentListItem
        key={score.id}
        golferActive={golferActive}
        score={score}
        filter={filter}
        useScaling={useScaling}
        hasHandicapIndex={hasHandicapIndex}
        nineHole={nineHole}
        deletedScore={false}
        style={style}
        trialGolfer={trialGolfer}
        onEditRoundStats={onEditRoundStats}
        onViewRoundStats={() => onViewRoundStats(score, true)}
        onDeleteRoundStats={this.onDeleteRoundStats}
      />
    ));
  }

  renderCombinedScores() {
    const {
      filter,
      onEditRoundStats,
      onViewRoundStats,
      recentScores,
      deletedScores,
      revisionScores,
      scores9Holes,
      golferActive,
      trialGolfer,
      useScaling,
      hasHandicapIndex,
    } = this.props;
    const displayRecentScores =
      recentScores.length > 0 ||
      scores9Holes.length > 0 ||
      deletedScores.length > 0;

    const allRecentScores = [
      ...this.markScoresAsDeleted(deletedScores, true),
      ...this.markScoresAsDeleted(recentScores, false),
    ];

    return (
      <Fragment>
        {displayRecentScores && (
          <Fragment>
            <h1 className="h6_attributes border-top">Recent Scores</h1>

            {this.sortScores(allRecentScores).map((score) => (
              <ContentListItem
                key={score.id}
                golferActive={golferActive}
                trialGolfer={trialGolfer}
                score={score}
                filter={filter}
                nineHole={false}
                deletedScore={score.deleted}
                useScaling={useScaling}
                hasHandicapIndex={hasHandicapIndex}
                style={
                  score.deleted
                    ? { fontStyle: "italic", color: "#737373" }
                    : { fontStyle: "italic" }
                }
                onEditRoundStats={onEditRoundStats}
                onViewRoundStats={() => onViewRoundStats(score, true)}
                onDeleteRoundStats={this.onDeleteRoundStats}
              />
            ))}
            {scores9Holes.length > 0 && this.renderContent(scores9Holes, true)}
          </Fragment>
        )}

        {revisionScores && revisionScores.length > 0 && (
          <Fragment>
            <h1
              className={
                displayRecentScores
                  ? "h6_attributes border-bottom"
                  : "h6_attributes border-top"
              }
            >
              Revision Scores
            </h1>
            {revisionScores.map((score) => (
              <ContentListItem
                key={score.id}
                golferActive={golferActive}
                score={score}
                filter={filter}
                trialGolfer={this.props.trialGolfer}
                nineHole={false}
                deletedScore={false}
                useScaling={useScaling}
                hasHandicapIndex={hasHandicapIndex}
                onEditRoundStats={onEditRoundStats}
                onViewRoundStats={() => onViewRoundStats(score, true)}
                onDeleteRoundStats={this.onDeleteRoundStats}
                hasRevisionScores={this.state.hasRevisionScores}
              />
            ))}
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderNotFound() {
    const { notFoundText } = this.props;
    return (
      <NoResultsFound>
        <p>{notFoundText}</p>
      </NoResultsFound>
    );
  }

  render() {
    const scores = this.getDisplayScores();
    const {
      hideGraph,
      scores9Holes,
      deletedScores,
      additionalData,
      filter,
      showSumHeader,
      golferActive,
      useScaling,
      trialGolfer,
    } = this.props;

    const { showPccInfoModal, showDeleteRoundStatsModal } = this.state;
    const filteredAdditionalData =
      filter === ScoreType.BY_COURSE
        ? getAdditionalData({ scores }, filter)
        : additionalData;
    return (
      <Fragment>
        <ContentListHeader
          hideGraph={hideGraph}
          scores={scores}
          scores9Holes={scores9Holes}
        />
        {showSumHeader && (
          <ContentListSubHeader additionalData={filteredAdditionalData} />
        )}
        <div className="card__table">
          <div className="table__head">
            <div className="head__row">
              <div className="head__cell esc-score">Score</div>
              <div className="head__cell date">Date</div>
              {golferActive && !trialGolfer && (
                <div className="head__cell rating-slope">C.R./Slope</div>
              )}
              {golferActive && !trialGolfer && (
                <div className="head__cell pcc">
                  PCC
                  <button
                    onClick={this.onShowPccInfoModal}
                    className="btn blank"
                    style={{ paddingLeft: 3, paddingRight: 0 }}
                  >
                    <i className="material-icons-outlined">info</i>
                  </button>
                </div>
              )}
              {golferActive && !trialGolfer && (
                <div className="head__cell differential">Differential</div>
              )}
              <div className="head__cell course">Course</div>
              <div className="head__cell stats">Stats</div>
            </div>
          </div>
          <div className="table__body">
            {scores.length > 0 ||
            (filter == ScoreType.RECENT_AND_REVISION_SCORES &&
              deletedScores.length > 0) ||
            scores9Holes.length > 0
              ? filter === ScoreType.RECENT_AND_REVISION_SCORES
                ? this.renderCombinedScores()
                : this.renderContent(scores)
              : this.renderNotFound()}
          </div>
          <ScoreLegend useScaling={useScaling} />
        </div>
        <PccInfoModal
          isOpen={showPccInfoModal}
          onAbort={this.onClosePccInfoModal}
          onConfirm={this.onClosePccInfoModal}
        />
        <ConfirmDeleteStatsModal
          isOpen={showDeleteRoundStatsModal}
          onClose={() =>
            this.setState({
              showDeleteRoundStatsModal: false,
              scoreForDelete: undefined,
            })
          }
          onConfirm={this.onDeleteRoundStatsConfirm}
        />
      </Fragment>
    );
  }
}

ContentList.propTypes = {
  useScaling: PropTypes.bool,
  hasHandicapIndex: PropTypes.bool,
  scores: PropTypes.array.isRequired,
  onEditRoundStats: PropTypes.func.isRequired,
  onViewRoundStats: PropTypes.func.isRequired,
  onDeleteRoundStats: PropTypes.func.isRequired,
  selectedClub: PropTypes.string,
  notFoundText: PropTypes.string,
  hideGraph: PropTypes.bool,
  showSumHeader: PropTypes.bool,
  scores9Holes: PropTypes.array,
  recentScores: PropTypes.array,
  deletedScores: PropTypes.array,
  revisionScores: PropTypes.array,
  filter: PropTypes.string.isRequired,
  additionalData: PropTypes.shape({}),
};

ContentList.defaultProps = {
  selectedClub: null,
  hideGraph: false,
  showSumHeader: false,
  onEditRoundStats: () => {},
  onViewRoundStats: () => {},
  onDeleteRoundStats: () => {},
  scores9Holes: [],
  recentScores: [],
  deletedScores: [],
  revisionScores: [],
  additionalData: undefined,
};

const mapStateToProps = ({ postScoreReducer, profileReducer }) => {
  const handicapIndex =
    profileReducer.golferProfileFetchReducer.golferProfile?.handicap_index;

  return {
    editedScore: postScoreReducer.postEditRoundStatsReducer.editedScore,
    golferActive: profileReducer.golferProfileFetchReducer.golferActive,
    trialGolfer: profileReducer.golferProfileFetchReducer.trialGolfer,
    useScaling:
      profileReducer.golferProfileFetchReducer.golferProfile?.use_scaling,
    hasHandicapIndex: handicapIndex != "NH" && handicapIndex != "WD",
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateStatsScores,
      deleteRoundStats,
      postEditRoundStatsReset,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContentList);
